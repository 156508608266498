export default [
  {
    id: "4",
    ref: "102",
    description: "Sideboard with drawers in textured aluminium finish",
    type: "sideboard",
    category_id: "2",
    dimension1: "960",
    dimension2: "1440",
    dimension3: "480"
  },
  {
    id: "7",
    ref: "103",
    description: "Dining table in in limed solid oak with top in fossil slate",
    type: "dtable",
    category_id: "1",
    dimension1: "750",
    dimension2: "1500",
    dimension3: "1500"
  },
  {
    id: "8",
    ref: "107",
    description:
      "Dining table in solid sycamore with top and base in polished lava stone",
    type: "dtable",
    category_id: "1",
    dimension1: "750",
    dimension2: "1350",
    dimension3: "1350"
  },
  {
    id: "9",
    ref: "108",
    description:
      "Sideboard in high gloss polyester lacquer with nickel plated handles",
    type: "sideboard",
    category_id: "2",
    dimension1: "880",
    dimension2: "1600",
    dimension3: "400"
  },
  {
    id: "11",
    ref: "132",
    description:
      "Sideboard in macassar ebony veneer with ebony inlay on all edges and handles in solid ebony",
    type: "sideboard",
    category_id: "2",
    dimension1: "900",
    dimension2: "1650",
    dimension3: "375"
  },
  {
    id: "12",
    ref: "130",
    description:
      "Cabinet in macassar ebony veneer with ebony inlay on all edges and doors in bleached vellum with nickel plated feet",
    type: "sideboard",
    category_id: "2",
    dimension1: "1080",
    dimension2: "2160",
    dimension3: "540"
  },
  {
    id: "13",
    ref: "118",
    description:
      "Console in macassar ebony veneer with ebony inlay on all edges and feet in solid ebony",
    type: "chtable",
    category_id: "4",
    dimension1: "810",
    dimension2: "540",
    dimension3: "1620"
  },
  {
    id: "14",
    ref: "104",
    description:
      "Cabinet in macassar ebony veneer with ebony inlay on all edges and bronze finish handle",
    type: "cabinet",
    category_id: "2",
    dimension1: "1200",
    dimension2: "1200",
    dimension3: "600"
  },
  {
    id: "17",
    ref: "101",
    description:
      "Cabinet in white gold leaf with base and inside in macassar ebony veneer with ebony inlay on all edges and ebony handle",
    type: "cabinet",
    category_id: "2",
    dimension1: "2250",
    dimension2: "1125",
    dimension3: "675"
  },
  {
    id: "18",
    ref: "125",
    description:
      "Cabinet in white gold leaf with base and inside in polyester lacquer and nickel plated handle",
    type: "cabinet",
    category_id: "2",
    dimension1: "2100",
    dimension2: "1050",
    dimension3: "600"
  },
  {
    id: "20",
    ref: "119",
    description: "Hall table in high gloss polyester lacquer",
    type: "chtable",
    category_id: "4",
    dimension1: "900",
    dimension2: "900",
    dimension3: "300"
  },
  {
    id: "21",
    ref: "116",
    description:
      "Extending dining table in indian rosewood veneer with ebony inlay on all edges and stainless steel feet",
    type: "dtable",
    category_id: "1",
    dimension1: "750",
    dimension2: "2700",
    dimension3: "1200"
  },
  {
    id: "22",
    ref: "117",
    description:
      "Dining table in bog oak veneer with boxwood inlay on all edges",
    type: "dtable",
    category_id: "1",
    dimension1: "730",
    dimension2: "2040",
    dimension3: "1080"
  },
  {
    id: "23",
    ref: "109",
    description:
      "Cabinet in South American rosewood veneer with ebony inlay on all edges and nickel plated handle",
    type: "cabinet",
    category_id: "2",
    dimension1: "1350",
    dimension2: "900",
    dimension3: "450"
  },
  {
    id: "24",
    ref: "126",
    description: "Cabinet in fumed oak veneer with nickel plated handle",
    type: "cabinet",
    category_id: "2",
    dimension1: "900",
    dimension2: "900",
    dimension3: "450"
  },
  {
    id: "25",
    ref: "112",
    description:
      "Coffee table in bog oak veneer with boxwood inlay on all edges",
    type: "ctable",
    category_id: "3",
    dimension1: "360",
    dimension2: "1200",
    dimension3: "1200"
  },
  {
    id: "27",
    ref: "123",
    description:
      "Extending dining table in macassar ebony veneer with ebony inlay on all edges and bases in vellum",
    type: "dtable",
    category_id: "1",
    dimension1: "720",
    dimension2: "3240",
    dimension3: "1260"
  },
  {
    id: "28",
    ref: "122",
    description:
      "Sideboard in vellum and macassar ebony veneer with ebony inlay on all edges and nickel plated handles",
    type: "sideboard",
    category_id: "2",
    dimension1: "900",
    dimension2: "1980",
    dimension3: "540"
  },
  {
    id: "29",
    ref: "120",
    description:
      "Cabinet in pitted bronze finish with legs and handle in bleached oak",
    type: "cabinet",
    category_id: "2",
    dimension1: "1080",
    dimension2: "720",
    dimension3: "360"
  },
  {
    id: "30",
    ref: "131",
    description:
      "Beside table in indian rosewood veneer with ebony inlay on all edges and bleached vellum drawer",
    type: "sebtable",
    category_id: "3",
    dimension1: "480",
    dimension2: "480",
    dimension3: "480"
  },
  {
    id: "31",
    ref: "110",
    description: "Hall table in oak veneer with silver finish",
    type: "chtable",
    category_id: "4",
    dimension1: "900",
    dimension2: "900",
    dimension3: "300"
  },
  {
    id: "32",
    ref: "129",
    description: "Console in ebonised oak veneer",
    type: "chtable",
    category_id: "4",
    dimension1: "840",
    dimension2: "1260",
    dimension3: "420"
  },
  {
    id: "34",
    ref: "134",
    description:
      "Wall hung hall table in macassar ebony veneer with ebony inlay on all edges",
    type: "chtable",
    category_id: "4",
    dimension1: "900",
    dimension2: "900",
    dimension3: "300"
  },
  {
    id: "35",
    ref: "135",
    description:
      "Sofa-end table in Indian rosewood veneer with legs in solid ebony",
    type: "sebtable",
    category_id: "3",
    dimension1: "600",
    dimension2: "600",
    dimension3: "600"
  },
  {
    id: "38",
    ref: "138",
    description: "Dining table in limed oak veneer with nickel plated banding",
    type: "dtable",
    category_id: "1",
    dimension1: "750",
    dimension2: "1900",
    dimension3: "1900"
  },
  {
    id: "39",
    ref: "139",
    description:
      "Shelving unit in bubinga veneer with ebony inlay on all edges",
    type: "sideboard",
    category_id: "2",
    dimension1: "880",
    dimension2: "3840",
    dimension3: "400"
  },
  {
    id: "40",
    ref: "140",
    description:
      "Sofa-end table in burr walnut veneer with ebony inlay on all edges",
    type: "sebtable",
    category_id: "3",
    dimension1: "510",
    dimension2: "900",
    dimension3: "900"
  },
  {
    id: "41",
    ref: "141",
    description:
      "Console in macassar ebony veneer with ebony inlay on all edges and nickel plated feet",
    type: "chtable",
    category_id: "4",
    dimension1: "750",
    dimension2: "1350",
    dimension3: "450"
  },
  {
    id: "42",
    ref: "142",
    description:
      "Extending dining table in wenge veneer with ebony inlay on all edges and nickel plated base edging",
    type: "dtable",
    category_id: "1",
    dimension1: "730",
    dimension2: "4800",
    dimension3: "1600"
  },
  {
    id: "43",
    ref: "143",
    description:
      "Hall table in macassar ebony veneer with ebony inlay on all edges",
    type: "chtable",
    category_id: "4",
    dimension1: "900",
    dimension2: "900",
    dimension3: "300"
  },
  {
    id: "44",
    ref: "144",
    description:
      "Sideboard in fumed oak veneer with ebony inlay on all edges and handles in solid ebony",
    type: "cabinet",
    category_id: "2",
    dimension1: "750",
    dimension2: "1500",
    dimension3: "375"
  },
  {
    id: "45",
    ref: "145",
    description:
      "Coffee table in macassar ebony veneer with ebony inlay on all edges and legs in solid ebony",
    type: "ctable",
    category_id: "3",
    dimension1: "360",
    dimension2: "1200",
    dimension3: "1200"
  },
  {
    id: "46",
    ref: "146",
    description:
      "Sideboard in fumed oak veneer with ebony inlay on all edges and nickel plated handles",
    type: "sideboard",
    category_id: "2",
    dimension1: "800",
    dimension2: "2400",
    dimension3: "400"
  },
  {
    id: "47",
    ref: "147",
    description:
      "Coffee table in macassar ebony veneer with ebony inlay on all edges",
    type: "ctable",
    category_id: "3",
    dimension1: "400",
    dimension2: "1200",
    dimension3: "600"
  },
  {
    id: "48",
    ref: "148",
    description:
      "Desk for two in pitted copper finish with drawers in pitted steel finish",
    type: "dtable",
    category_id: "1",
    dimension1: "720",
    dimension2: "2800",
    dimension3: "560"
  },
  {
    id: "51",
    ref: "151",
    description: "Console in bleached oak veneer",
    type: "chtable",
    category_id: "4",
    dimension1: "750",
    dimension2: "1500",
    dimension3: "500"
  },
  {
    id: "52",
    ref: "152",
    description: "Wall hung hall table in pitted bronze finish",
    type: "chtable",
    category_id: "4",
    dimension1: "900",
    dimension2: "900",
    dimension3: "300"
  },
  {
    id: "53",
    ref: "153",
    description: "Console in bog oak veneer with boxwood inlay on all edges",
    type: "chtable",
    category_id: "4",
    dimension1: "780",
    dimension2: "1300",
    dimension3: "390"
  },
  {
    id: "54",
    ref: "154",
    description:
      "Dining table in fumed oak veneer with ebony inlay on all edges and columns in macassar ebony veneer",
    type: "dtable",
    category_id: "1",
    dimension1: "740",
    dimension2: "2220",
    dimension3: "2220"
  },
  {
    id: "55",
    ref: "155",
    description:
      "Dining table in Indian rosewood veneer with ebony inlay on all edges",
    type: "dtable",
    category_id: "1",
    dimension1: "730",
    dimension2: "2800",
    dimension3: "1120"
  },
  {
    id: "56",
    ref: "156",
    description: "Desk in quilted maple veneer with boxwood inlay on all edges",
    type: "dtable",
    category_id: "1",
    dimension1: "730",
    dimension2: "1800",
    dimension3: "800"
  },
  {
    id: "57",
    ref: "157",
    description:
      "Bedside table in quilted maple veneer with boxwood inlay on all edges",
    type: "",
    category_id: "3",
    dimension1: "490",
    dimension2: "480",
    dimension3: "480"
  },
  {
    id: "58",
    ref: "158",
    description:
      "Bedside table in quilted maple veneer with boxwood inlay on all edges",
    type: "",
    category_id: "3",
    dimension1: "490",
    dimension2: "960",
    dimension3: "480"
  },
  {
    id: "60",
    ref: "160",
    description: "Console in satinwood veneer with ebony inlay on all edges",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "1600",
    dimension3: "400"
  },
  {
    id: "61",
    ref: "161",
    description:
      "Console/dressing table in ripple sycamore veneer with ebony inlay on all edges and drawer front in Macassar ebony veneer.",
    type: "",
    category_id: "4",
    dimension1: "780",
    dimension2: "1200",
    dimension3: "480"
  },
  {
    id: "62",
    ref: "162",
    description:
      "Dining table in Lappia Green granite with legs in solid ebony",
    type: "",
    category_id: "1",
    dimension1: "720",
    dimension2: "1200",
    dimension3: "1200"
  },
  {
    id: "63",
    ref: "163",
    description:
      "Dressing table in quilted maple veneer with boxwood inlay on all edges",
    type: "",
    category_id: "4",
    dimension1: "720",
    dimension2: "1200",
    dimension3: "600"
  },
  {
    id: "64",
    ref: "164",
    description: "Cabinet in whitened and limed oak veneer with nickel handles",
    type: "",
    category_id: "2",
    dimension1: "800",
    dimension2: "1840",
    dimension3: "460"
  },
  {
    id: "65",
    ref: "165",
    description: "Alcove table in whitened and limed oak veneer",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "800",
    dimension3: "400"
  },
  {
    id: "66",
    ref: "166",
    description:
      "Console in Indian rosewood veneer with ebony inlay on all edges",
    type: "",
    category_id: "4",
    dimension1: "825",
    dimension2: "825",
    dimension3: "330"
  },
  {
    id: "67",
    ref: "167",
    description:
      "Sideboard in Indian rosewood veneer with ebony inlay on all edges and handles solid ebony.",
    type: "",
    category_id: "2",
    dimension1: "825",
    dimension2: "1870",
    dimension3: "440"
  },
  {
    id: "68",
    ref: "168",
    description: "Console table in Chinese red high-gloss polyester lacquer.",
    type: "",
    category_id: "4",
    dimension1: "810",
    dimension2: "810",
    dimension3: "270"
  },
  {
    id: "69",
    ref: "169",
    description: "Desk in fumed oak veneer with boxwood inlay on all edges.",
    type: "",
    category_id: "1",
    dimension1: "720",
    dimension2: "1600",
    dimension3: "640"
  },
  {
    id: "70",
    ref: "170",
    description: "Side table in fumed oak with boxwood inlay on all edges.",
    type: "",
    category_id: "3",
    dimension1: "720",
    dimension2: "360",
    dimension3: "720"
  },
  {
    id: "71",
    ref: "171",
    description: "Sofa-end table in fumed oak with boxwood inlay on all edges.",
    type: "",
    category_id: "3",
    dimension1: "480",
    dimension2: "480",
    dimension3: "480"
  },
  {
    id: "72",
    ref: "172",
    description:
      "Coffee table in teal blue Valchromat with ebony inlay on all edges.",
    type: "",
    category_id: "3",
    dimension1: "300",
    dimension2: "1200",
    dimension3: "750"
  },
  {
    id: "73",
    ref: "173",
    description: "Sofa-end table in Aqua polished cement micro-topping.",
    type: "",
    category_id: "3",
    dimension1: "405",
    dimension2: "540",
    dimension3: "540"
  },
  {
    id: "74",
    ref: "174",
    description:
      "Dining table in bleached wenge veneer with stainless steel feet.",
    type: "",
    category_id: "1",
    dimension1: "725",
    dimension2: "1500",
    dimension3: "900"
  },
  {
    id: "75",
    ref: "175",
    description:
      "Writing table in ripple maple veneer with boxwood inlay on all edges.",
    type: "",
    category_id: "1",
    dimension1: "720",
    dimension2: "1350",
    dimension3: "810"
  },
  {
    id: "76",
    ref: "176",
    description:
      "Storage console in ripple maple veneer with boxwood inlay on all edges.",
    type: "",
    category_id: "2",
    dimension1: "810",
    dimension2: "2700",
    dimension3: "540"
  },
  {
    id: "77",
    ref: "177",
    description: "Console in fumed oak veneer with boxwood inlay on all edges.",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "1200",
    dimension3: "400"
  },
  {
    id: "78",
    ref: "178",
    description: "Desk in fumed oak veneer with ebony inlay on all edges.",
    type: "",
    category_id: "1",
    dimension1: "720",
    dimension2: "1600",
    dimension3: "600"
  },
  {
    id: "79",
    ref: "179",
    description: "Console in fumed oak veneer with ebony inlay on all edges.",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "1280",
    dimension3: "480"
  },
  {
    id: "80",
    ref: "180",
    description:
      "Chest of drawers in white ebony veneer and purple Valchromat with ebony inlay on edges.",
    type: "",
    category_id: "2",
    dimension1: "800",
    dimension2: "1440",
    dimension3: "480"
  },
  {
    id: "81",
    ref: "181",
    description:
      "Console table in mint-green Valchromat with ebony inlay on all edges.",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "960",
    dimension3: "320"
  },
  {
    id: "82",
    ref: "182",
    description: "Console in fumed oak veneer with boxwood inlay on all edges.",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "960",
    dimension3: "320"
  },
  {
    id: "83",
    ref: "183",
    description: "Console in fumed oak veneer with boxwood inlay on all edges.",
    type: "",
    category_id: "4",
    dimension1: "800",
    dimension2: "960",
    dimension3: "320"
  }
];
